import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { graphql } from "gatsby"
// import Hero from "../components/hero/index"
import { StaticImage } from "gatsby-plugin-image"

const NUQ37Page = ({ data, location }) => {
  // const page = data && data.page

  return (
    <Layout location={location}>
      <SEO title="About NuQ" />
      {/* <div className="page-headline">
        <div className="container">
          <div className="section-heading text-center">
            <h6 className="font-weight-bold text-uppercase text-white-50 flair">
              {page.title}
            </h6>
          </div>
        </div>
      </div> */}
      <div className="page-content about-page" style={{ padding: "150px 0" }}>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                <div className="page-content-title text-left d-flex alight-items-center justify-content-start">
                  <h2 className="section-title text-d-cyan mr-4">
                    What is <span className="title-underline">NuQ</span>
                  </h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="148.679"
                    height="161.784"
                    viewBox="0 0 148.679 161.784"
                    className="about-nuq-svg"
                  >
                    <g
                      id="Group_72869"
                      data-name="Group 72869"
                      transform="translate(-196.991 -290.005)"
                    >
                      <g id="Group_73507" data-name="Group 73507">
                        <path
                          id="Path_37282"
                          data-name="Path 37282"
                          d="M74.768,110.623c-15.446,0-29.252-6.793-39.922-19.649C18.333,71.091,10.551,37.87,15.054,6.341a101.62,101.62,0,0,1,4.684-19.558c8.471-24.112,28.927-37.944,56.1-37.944a82.6,82.6,0,0,1,9.915.612,97.574,97.574,0,0,1,52.74,24.385A79.909,79.909,0,0,1,152.543-9.43C172.5,22.646,161.99,59.718,124.41,89.764c-14.873,11.906-29.356,18.777-43.058,20.456A54.328,54.328,0,0,1,74.768,110.623Z"
                          transform="translate(183.198 341.166)"
                          fill="#01e1ca"
                        />
                        <text
                          id="SALMONWITH"
                          transform="translate(211 377.522)"
                          fill="#fff"
                          font-size="38"
                          font-family="Rubik-Medium, Rubik"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">
                            NuQ™
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </svg>
                </div>
                {/* <p className="text-d-green-darker text-20"> */}
                <p className="text-d-green-darker" style={{ fontSize: "22px" }}>
                  Calorie counting, fad diets, weight management programs, etc.
                  have failed miserably to put a dent in our declining health
                  and exploding obesity globally! We are introducing a new
                  health paradigm designed to
                  <b> Disrupt Unhealthy Lifestyles.</b>
                  {/* <b>
                    <span className="text-underline">Dr. Lulu Hunt Peters</span>
                  </b>
                  , a little know physician in <b>1918</b>!!! */}
                </p>
                <p className="text-d-green-darker" style={{ fontSize: "22px" }}>
                  We think it's time for a <b>Wellness Revolution</b>, which is
                  why we created <b>NuQ</b>. Regardless of size, sex, age and
                  health issue we all need to consume over 30 nutrients
                  including 13 vitamins and 15 minerals regularly.
                </p>
                <p className="text-d-green-darker" style={{ fontSize: "22px" }}>
                  <b>Nutrient Quotient (NuQ&#x2122;)</b> is the world’s first
                  EVER nutrient density calculator that most accurately measures
                  complete <u>nutrient intake</u> in our foods. It’s like
                  <b className="ml-1">Credit Scores for Food.</b>.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StaticImage
                  placeholder="dominantColor"
                  className="img-fluid my-5"
                  src="../images/nuq-index-cube.png"
                  alt="About Image Vegetables"
                  quality={100}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NUQ37Page
